import { ProductCard } from 'components'
import { useApp } from 'context'

import styles from './popularProducts.module.scss'

export default function PopularProducts() {
  const { popularProducts } = useApp()

  return (
    <div className={styles.wrapper}>
      <h2>Essential Products</h2>

      <div className={styles.products}>
        {popularProducts?.map(product => (
          <ProductCard key={product.id} {...product} />
        ))}
      </div>
      <button aria-label="View More" className={styles.viewMore}>
        View more
      </button>
    </div>
  )
}
