import { Checkbox } from 'elements'
import { priceRangeList } from 'data'
import { PInterface } from 'types'
//import IPrice from './IPrice'

import styles from './price.module.scss'

export default function Price({
  price,
  searchTerms,
  setSearchTerms,
}: PInterface) {
  const handleCheckboxChange = (e: boolean, pLocal: string) => {
    if (e) {
      const newPrice = price ? [...price, pLocal] : [pLocal]
      setSearchTerms({ ...searchTerms, price: newPrice as any })
    } else {
      const newPrice = price?.filter(item => item !== pLocal)
      setSearchTerms({ ...searchTerms, price: newPrice })
    }
  }

  return (
    <div className={styles.main}>
      <h3>Price</h3>
      <div className={styles.price}>
        {Object.keys(priceRangeList).map((pLocal, index) => (
          <div className={styles.pRange} key={index}>
            <Checkbox
              name={pLocal as string}
              checked={price?.includes(pLocal as any)}
              onChange={e => {
                handleCheckboxChange(e, pLocal as string)
              }}
            />
            <span
              style={
                price?.includes(pLocal as any)
                  ? { color: 'var(--black)' }
                  : { color: 'var(--grey400)' }
              }
            >
              ${priceRangeList[pLocal as keyof typeof priceRangeList].min} - $
              {priceRangeList[pLocal as keyof typeof priceRangeList].max}
            </span>
          </div>
        ))}
      </div>

      {/* <IPrice /> */}
    </div>
  )
}
