import { useState, useEffect } from 'react'

import { CartItemsType, CartReturnInterface, AddToCartType } from 'types'

export const Cart = (): CartReturnInterface => {
  const [cartItems, setCartItems] = useState<CartItemsType>()
  const [cartItemsCount, setCartItemsCount] = useState<number>(0)
  const [cartItemsSubtotal, setCartItemsSubtotal] = useState<number>(0)
  const [cartItemsTax, setCartItemsTax] = useState<number>(0)
  const [cartItemsShipping, setCartItemsShipping] = useState<number>(0)
  const [cartPriceTotal, setCartPriceTotal] = useState<number>(0)

  useEffect(() => {
    setCartPriceTotal(
      cartItems?.reduce((acc, item) => {
        if (item?.product?.price && item.count)
          return acc + item.product.price * item.count
        return acc
      }, 0) || 0
    )

    setCartItemsCount(
      cartItems?.reduce((acc, item) => {
        if (item?.count) return acc + item.count
        return acc
      }, 0) || 0
    )

    setCartItemsTax(cartPriceTotal * 0.2)
    setCartItemsSubtotal(cartPriceTotal - cartItemsTax)
    setCartItemsShipping(cartItemsSubtotal > 20000 ? 0 : 1000)
  }, [cartItems, cartPriceTotal, cartItemsSubtotal, cartItemsTax])

  const addToCart = ({ product, count, color }: AddToCartType) => {
    setCartItems(prev => {
      if (prev) return [...prev, { product, count, color }]
      return [{ product, count, color }]
    })
  }

  const removeFromCart = (id: string | number) => {
    setCartItems(prev => {
      if (prev) return prev.filter(item => item?.product?.id !== id)
    })
  }

  return {
    cartItems,
    addToCart,
    removeFromCart,
    cartItemsCount,

    values: {
      cartPriceTotal,
      cartItemsTax,
      cartItemsShipping,
      cartItemsSubtotal,
    },
  }
}
