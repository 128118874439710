import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { Icon, Checkbox } from 'elements'
import { categoriesList } from 'data'
import { useApp } from 'context'

import TopBar from './topBar'
import CategoriesBar from './categoriesBar'
import MobileMenu from './mobileMenu/mobileMenu'

import styles from './navbar.module.scss'
import { Paths } from 'routes'

export default function Navbar() {
  const [showCategories, setShowCategories] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const { cartItemsCount, isLoggedIn } = useApp()

  useEffect(() => {
    const navbar = document.getElementById('top')
    const navToHold = document.getElementById('navToHold')
    const navbarHeight = navbar?.offsetHeight

    const handleScroll = () => {
      if (window.scrollY > navbarHeight!) {
        navToHold?.classList.add(styles.scrolled)
      } else {
        navToHold?.classList.remove(styles.scrolled)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className={styles.nav} id="top">
      <TopBar
        setOpenMobileMenu={setOpenMobileMenu}
        openMobileMenu={openMobileMenu}
      />
      <MobileMenu open={openMobileMenu} />
      <nav className={styles.mainNav} id="navToHold">
        <Link to="/" aria-label="Home">
          <Icon name="logo" className={styles.logo} />
        </Link>
        <div className={styles.search}>
          <div className={styles.searchInner}>
            <input
              type="text"
              placeholder="Search for products..."
              id="search"
            />
            <div className={styles.categories}>
              <button
                aria-label="All Categories"
                onClick={() => setShowCategories(!showCategories)}
              >
                All Categories <Icon name="down" />
              </button>
            </div>
            <label htmlFor="search">
              <Icon name="search" />
            </label>
          </div>
          {showCategories ? (
            <div className={styles.categoriesOpen}>
              {categoriesList.map(({ name }, index) => (
                <div
                  className={styles.category}
                  style={{ animationDelay: `${0.1 + index * 0.1}s` }}
                  key={index}
                >
                  <Checkbox name={name} id={name.toLowerCase()} />
                  <label htmlFor={name.toLowerCase()}>{name}</label>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className={styles.rightLinks}>
          <Link
            className={cn(styles.link, styles.account)}
            to={isLoggedIn ? Paths.Account : Paths.Login}
          >
            <Icon name="user" />
            {isLoggedIn ? 'Account' : 'Login'}
          </Link>
          <Link className={cn(styles.link, styles.cart)} to="cart">
            <Icon name="cart" />
            Cart
            <span className={styles.cartItemsCount}>{cartItemsCount}</span>
          </Link>
        </div>
        <button className={styles.mobileSearch}>
          <Icon name="search" />
        </button>
      </nav>
      <CategoriesBar />
    </div>
  )
}
