import { forwardRef, ReactNode } from 'react'
import Carousel, { ReactElasticCarouselProps } from 'react-elastic-carousel'
import cn from 'classnames'

import { Icon } from 'elements'

import styles from './carousel.module.scss'

export const SmartLifeCarousel = forwardRef<
  any,
  ReactElasticCarouselProps & { children: ReactNode[] }
>((props, ref) => (
  <Carousel
    ref={ref}
    {...props}
    className={cn(styles.carousel, props.className)}
    renderArrow={({ type, onClick }) => (
      <div onClick={onClick}>
        {type === 'PREV' ? (
          <button className={styles.arrowLeft}>
            <Icon name="arrow" />
          </button>
        ) : (
          <button className={styles.arrowRight}>
            <Icon name="arrow" />
          </button>
        )}
      </div>
    )}
  />
))
