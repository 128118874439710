import { Image } from 'elements'

import styles from './styles.module.scss'

type ProductPromoCardProps = {
  promoImg: string
  promoName: string
  productCount: number
}

const promoDetails = {
  promoImg: 'https://picsum.photos/514/460',
  promoName: 'Summer Sale',
  productCount: 10,
}

function ProductPromoCard(props: ProductPromoCardProps) {
  const { promoImg, promoName, productCount } = props

  return (
    <div className={styles.card}>
      <Image src={promoImg} alt={promoName} width={275} height={252} />
      <div className={styles.textCard}>
        <h3>{promoName}</h3>
        <p>{productCount} products</p>
      </div>
    </div>
  )
}

export default function ProductPromoCards() {
  return (
    <div className={styles.rightPromoCards}>
      <ProductPromoCard {...promoDetails} />
      <ProductPromoCard {...promoDetails} />
    </div>
  )
}
