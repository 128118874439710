import { Icon } from 'elements'

import styles from './select.module.scss'

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  id: string
  children: React.ReactNode
}

export default function Select({
  name,
  id,
  children,
  ...props
}: SelectProps): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <select name={name} id={id} {...props}>
        {children}
      </select>
      <Icon name="down" />
    </div>
  )
}
