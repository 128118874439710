import { Link } from 'react-router-dom'

import { Icon } from 'elements'

import styles from './navbar.module.scss'

export default function CategoriesBar() {
  return (
    <div className={styles.categoriesBar}>
      <div className={styles.categories}>
        <Link className={styles.category} to="/search" state={'accessories'}>
          <Icon name="accessories" />
          <p>Accessories</p>
        </Link>
        <Link className={styles.category} to="/search" state={'lighting'}>
          <Icon name="lighting" />
          <p>Lighting</p>
        </Link>
        <Link className={styles.category} to="/search" state={'routers'}>
          <Icon name="routers" />
          <p>Routers</p>
        </Link>
        <Link className={styles.category} to="/search" state={'sockets-plugs'}>
          <Icon name="socketsPlugs" />
          <p>Sockets & Plugs</p>
        </Link>
        <Link className={styles.category} to="/search" state={'security'}>
          <Icon name="security" />
          <p>Security</p>
        </Link>
        <Link className={styles.category} to="/search" state={'management'}>
          <Icon name="management" />
          <p>Management</p>
        </Link>
      </div>
    </div>
  )
}
