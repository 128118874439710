import { useState, useEffect } from 'react'

import { useApp } from 'context'
import { SmartLifeCarousel, useWindowWidth } from 'elements'
import { ProductCard } from 'components'

import styles from './alsoBought.module.scss'

export default function AlsoBought() {
  const { popularProducts } = useApp()
  const { width } = useWindowWidth()
  const [numberOfItems, setNumberOfItems] = useState(4)

  useEffect(() => {
    if (width > 1007) setNumberOfItems(4)
    else if (width > 720) setNumberOfItems(3)
    else if (width > 640) setNumberOfItems(2)
    else setNumberOfItems(1)
  }, [width])

  return (
    <div className={styles.main}>
      <h1>Your top picks</h1>
      <SmartLifeCarousel
        itemsToShow={numberOfItems}
        itemsToScroll={1}
        pagination={false}
        isRTL={false}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
      >
        {popularProducts?.map(product => (
          <ProductCard key={product.id} {...product} />
        ))}
      </SmartLifeCarousel>
    </div>
  )
}
