import { Routes, Route } from 'react-router-dom'

import { commonRoutes } from './commonRoutes'
import { authenticatedRoutes } from './authenticatedRoutes'
import { adminRoutes } from './adminRoutes'
import { Login } from 'pages'

export * from './paths'

export const ROUTES = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    {commonRoutes.map(route => (
      <Route key={route.path} {...route} />
    ))}
  </Routes>
)

export const AuthenticatedROUTES = () => (
  <Routes>
    {commonRoutes.map(route => (
      <Route key={route.path} {...route} />
    ))}
    {authenticatedRoutes.map(route => (
      <Route key={route.path} {...route} />
    ))}
  </Routes>
)

export const AdminROUTES = () => (
  <Routes>
    {commonRoutes.map(route => (
      <Route key={route.path} {...route} />
    ))}
    {authenticatedRoutes.map(route => (
      <Route key={route.path} {...route} />
    ))}
    {adminRoutes.map(route => (
      <Route key={route.path} {...route} />
    ))}
  </Routes>
)
