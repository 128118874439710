import { useEffect, useState } from 'react'

import { sampleProduct } from 'data'

import {
  ProductsReturnTypeInterface,
  ProductInterface,
  ProductTypesType,
  SearchReturnType,
  SearchPropsType,
} from 'types'

const p: ProductInterface[] = Array.from({ length: 8 }, () => ({
  ...sampleProduct,
  id: Math.random(),
}))

export const Products = (): ProductsReturnTypeInterface => {
  const [products, setProducts] = useState<ProductInterface[]>([])
  const [popularProducts, setPopularProducts] = useState<ProductInterface[]>([])
  const [newProducts, setNewProducts] = useState<ProductInterface[]>([])
  const [promoProducts, setPromoProducts] = useState<ProductInterface[]>([])

  useEffect(() => {
    setProducts(p)
    setPopularProducts(p)
    setNewProducts(p)
    setPromoProducts(p)
  }, [])

  const search = (props: SearchPropsType): SearchReturnType => {
    const { price = [], category = [], brand = [], term = '' } = props
    const { itemSort = 'mostPopular' } = props

    const foundProducts = products.filter(product =>
      product.title.toLowerCase().includes(term.toLowerCase())
    )

    return {
      term,
      tags: { price, category, brand },
      products: foundProducts,
      itemSort,
    }
  }

  const fetchMoreProducts = async (type: ProductTypesType) => {
    const p: ProductInterface[] = Array.from({ length: 8 }, () => ({
      ...sampleProduct,
      id: Math.random(),
    }))

    if (type === 'popular') {
      setPopularProducts(prev => [...prev, ...p])
    } else if (type === 'new') {
      setNewProducts(prev => [...prev, ...p])
    } else if (type === 'promo') {
      setPromoProducts(prev => [...prev, ...p])
    }
  }

  const getSingleProduct = (id: string | number) =>
    products.find(product => product.id === id)

  return {
    search,
    products,
    popularProducts,
    newProducts,
    promoProducts,
    fetchMoreProducts,
    getSingleProduct,
  }
}
