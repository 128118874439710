import { FC, SVGProps } from 'react'

import { IconNameEnum } from 'types'

import { ReactComponent as accessories } from './svgs/accessories.svg'
import { ReactComponent as lighting } from './svgs/lighting.svg'
import { ReactComponent as management } from './svgs/management.svg'
import { ReactComponent as routers } from './svgs/routers.svg'
import { ReactComponent as socketsPlugs } from './svgs/socketsPlugs.svg'
import { ReactComponent as security } from './svgs/security.svg'
import { ReactComponent as cart } from './svgs/cart.svg'
import { ReactComponent as deliver } from './svgs/deliver.svg'
import { ReactComponent as payment } from './svgs/payment.svg'
import { ReactComponent as returns } from './svgs/return.svg'
import { ReactComponent as support } from './svgs/support.svg'
import { ReactComponent as user } from './svgs/user.svg'
import { ReactComponent as add } from './svgs/add.svg'
import { ReactComponent as down } from './svgs/down.svg'
import { ReactComponent as logo } from './svgs/logo.svg'
import { ReactComponent as search } from './svgs/search.svg'
import { ReactComponent as footerShape } from './svgs/footerShape.svg'
import { ReactComponent as phone } from './svgs/phone.svg'
import { ReactComponent as mail } from './svgs/mail.svg'
import { ReactComponent as plus } from './svgs/plus.svg'
import { ReactComponent as appleLogo } from './svgs/logoApple.svg'
import { ReactComponent as googleLogo } from './svgs/logoGoogle.svg'
import { ReactComponent as asusLogo } from './svgs/logoAsus.svg'
import { ReactComponent as lenovoLogo } from './svgs/logoLenovo.svg'
import { ReactComponent as philipsLogo } from './svgs/logoPhilips.svg'
import { ReactComponent as dellLogo } from './svgs/logoDell.svg'
import { ReactComponent as canonLogo } from './svgs/logoCanon.svg'
import { ReactComponent as sonyLogo } from './svgs/logoSony.svg'
import { ReactComponent as leftPromo } from './svgs/leftPromo.svg'
import { ReactComponent as guest } from './svgs/guest.svg'
import { ReactComponent as close } from './svgs/close.svg'
import { ReactComponent as arrow } from './svgs/arrow.svg'
import { ReactComponent as star } from './svgs/star.svg'
import { ReactComponent as edit } from './svgs/edit.svg'
import { ReactComponent as deleteIcon } from './svgs/delete.svg'
import { ReactComponent as image } from './svgs/image.svg'
import { ReactComponent as minus } from './svgs/minus.svg'
import { ReactComponent as Crt } from './svgs/Crt.svg'
import { ReactComponent as leftArrow } from './svgs/leftArrow.svg'
import { ReactComponent as rightArrow } from './svgs/rightArrow.svg'
import { ReactComponent as profile } from './svgs/profile.svg'
import { ReactComponent as truck } from './svgs/truck.svg'

const icons: {
  [key in IconNameEnum]: FC<SVGProps<SVGSVGElement>>
} = {
  accessories,
  lighting,
  management,
  routers,
  security,
  socketsPlugs,
  cart,
  deliver,
  payment,
  returns,
  support,
  user,
  add,
  down,
  logo,
  search,
  footerShape,
  phone,
  mail,
  plus,
  appleLogo,
  googleLogo,
  asusLogo,
  lenovoLogo,
  philipsLogo,
  dellLogo,
  canonLogo,
  sonyLogo,
  leftPromo,
  guest,
  close,
  arrow,
  star,
  edit,
  delete: deleteIcon,
  image,
  minus,
  Crt,
  leftArrow,
  rightArrow,
  profile,
  truck,
}

type IconProps = {
  name: keyof typeof IconNameEnum
  color?: string
  className?: string
}

export const Icon = ({ name, color, className }: IconProps) => {
  const Ico = icons[name]

  return <Ico style={{ fill: color }} className={className} />
}
