import { useNavigate } from 'react-router-dom'

import { Icon, Image, slugify } from 'elements'
import { ProductInterface } from 'types'
import { useApp } from 'context'

import styles from './productCard.module.scss'

export default function ProductCard(props: ProductInterface) {
  const { title, price, images, discount, colors } = props
  const { addToCart } = useApp()
  const navigate = useNavigate()

  const preDiscountPrice =
    Math.round(price * ((100 + discount) / 100) * 100) / 100

  const link = `/product/${slugify(title)}`

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <Image src={images[0]} alt={title} width={514} height={460} />
        {discount > 0 ? (
          <div className={styles.discount}>
            <span>-{discount}%</span>
          </div>
        ) : null}
      </div>
      <h3 onClick={() => navigate(link)}>{title}</h3>

      <div className={styles.bottom}>
        <div className={styles.price}>
          {discount > 0 ? (
            <>
              <span className={styles.strikeThrough}>
                KSH {preDiscountPrice}
              </span>
              <span className={styles.dPrice}>KSH {price}</span>
            </>
          ) : (
            <span>KSH {price}</span>
          )}
        </div>
        <button
          aria-label="Add to cart"
          onClick={() =>
            addToCart({ product: props, count: 1, color: colors[0] })
          }
        >
          <span className={styles.cartTag}>Cart</span>
          <Icon name="plus" />
        </button>
      </div>
    </div>
  )
}
