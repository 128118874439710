import { Icon } from 'elements'

import styles from './rating.module.scss'

export default function Rating({ rating }: any) {
  rating = Math.round(rating)

  const stars = []
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<Icon key={i} color="var(--orange)" name="star" />)
    } else {
      stars.push(<Icon key={i} color="var(--grey400)" name="star" />)
    }
  }
  return <div className={styles.rating}>{stars}</div>
}
