import { ProductCard } from 'components'
import { SmartLifeCarousel, useWindowWidth } from 'elements'
import { useApp } from 'context'

import styles from './promoProducts.module.scss'

export default function PromoProducts() {
  const { promoProducts } = useApp()
  const { device } = useWindowWidth()
  const { isMobile, isTablet } = device

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Promotions</h3>

      <SmartLifeCarousel
        itemsToShow={isMobile ? 1 : isTablet ? 2 : 4}
        itemsToScroll={1}
        pagination={false}
        isRTL={false}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
        className={styles.carousel}
      >
        {promoProducts?.map(product => (
          <ProductCard key={product.id} {...product} />
        ))}
      </SmartLifeCarousel>
    </div>
  )
}
