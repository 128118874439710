import { ProductCard } from 'components'
import { useApp } from 'context'

import styles from '../popularProducts/popularProducts.module.scss'

export default function NewProducts() {
  const { newProducts } = useApp()
  return (
    <div className={styles.wrapper}>
      <h2>New Products</h2>

      <div className={styles.products}>
        {newProducts?.map(product => (
          <ProductCard key={product.id} {...product} />
        ))}
      </div>
      <button aria-label="View More" className={styles.viewMore}>
        View more
      </button>
    </div>
  )
}
