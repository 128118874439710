import { useState, useEffect } from 'react'

export const useWindowWidth: () => {
  width: number
  device: {
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
  }
} = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [device, setDevice] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  })

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (width < 640) {
      setDevice({
        isMobile: true,
        isTablet: false,
        isDesktop: false,
      })
      return
    } else if (width < 1007) {
      setDevice({
        isMobile: false,
        isTablet: true,
        isDesktop: false,
      })
      return
    }
    setDevice({
      isMobile: false,
      isTablet: false,
      isDesktop: true,
    })
  }, [width])

  return {
    width,
    device,
  }
}
