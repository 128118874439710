import { Paths } from 'routes/paths'
import * as Pages from 'pages'

export const adminRoutes = [
  {
    path: Paths.Admin,
    element: <Pages.Admin />,
  },
  {
    path: Paths.Products,
    element: <Pages.Products />,
  },
  {
    path: Paths.AddProduct,
    element: <Pages.AddProduct />,
  },
  {
    path: Paths.AdminProduct,
    element: <Pages.Product />,
  },
  {
    path: Paths.Categories,
    element: <Pages.AdminCategory />,
  },
  {
    path: Paths.ColorsAndBrands,
    element: <Pages.ColorsAndBrands />,
  },
]
