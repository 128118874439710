import { useState, useEffect } from 'react'
import cn from 'classnames'

import { Checkbox } from './checkbox'

import styles from './multiSelect.module.scss'

type MultiSelectProps = {
  options: string[]
  selected: string[] | undefined
  onChange: (selected: string[]) => void
  className?: string
}

type HandleChange = { option: string; checked: boolean }

export default function MultiSelect(props: MultiSelectProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { options, selected, onChange, className } = props

  const handleChange = ({ option, checked }: HandleChange) => {
    if (checked) return onChange(selected ? [...selected, option] : [option])
    onChange(selected ? selected.filter(_ => _ !== option) : [])
  }

  const selectedAsString = selected?.join(', ')

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const multiSelectInput = document.getElementById('multiSelectInput')
      if (multiSelectInput && !multiSelectInput.contains(target))
        setIsOpen(false)
    }
    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [])

  return (
    <div className={cn(styles.wrapper, className)} id="multiSelectInput">
      <button onClick={() => setIsOpen(_ => !_)}>{selectedAsString}</button>
      {isOpen ? (
        <div className={styles.openMultiSelect}>
          {options.map(option => (
            <div key={option} className={styles.option}>
              <Checkbox
                checked={selected?.includes(option)}
                onChange={checked => handleChange({ option, checked })}
                id={option}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
