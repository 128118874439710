import { brandsList } from 'data'
import { Checkbox } from 'elements'
import { IBrand } from 'types'

import styles from './brands.module.scss'

export default function Brands({ brand, searchTerms, setSearchTerms }: IBrand) {
  const handleCheckboxChange = (e: boolean, bLocal: string) => {
    if (e) {
      const newBrand = brand ? [...brand, bLocal] : [bLocal]
      setSearchTerms({ ...searchTerms, brand: newBrand })
    } else {
      const newBrand = brand?.filter(item => item !== bLocal)
      setSearchTerms({ ...searchTerms, brand: newBrand })
    }
  }

  return (
    <div className={styles.main}>
      <h3>Brands</h3>
      <div className={styles.brands}>
        {brandsList.map(bLocal => (
          <div className={styles.brand} key={bLocal}>
            <Checkbox
              name={bLocal}
              checked={brand?.includes(bLocal)}
              onChange={e => {
                handleCheckboxChange(e, bLocal)
              }}
            />
            <span
              style={
                brand?.includes(bLocal)
                  ? { color: 'var(--black)' }
                  : { color: 'var(--grey400)' }
              }
            >
              {bLocal}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
