import { useState, useEffect } from 'react'
import cn from 'classnames'

import { useWindowWidth, Icon, Checkbox } from 'elements'
import { categoriesList } from 'data'

import styles from './mobileMenu.module.scss'

export default function MobileMenu({ open }: { open: boolean }) {
  const [shouldOpen, setShouldOpen] = useState(false)
  const [showCategories, setShowCategories] = useState(false)
  const { device } = useWindowWidth()

  useEffect(() => {
    if (device.isMobile || device.isTablet) {
      setShouldOpen(true)
      return
    }

    setShouldOpen(false)
  }, [device])

  return (
    <div className={cn({ [styles.open]: open && shouldOpen }, styles.wrapper)}>
      <div className={styles.search}>
        <div className={styles.searchInner}>
          <input type="text" placeholder="Search for products..." id="search" />
          <label htmlFor="search">
            <Icon name="search" />
          </label>
        </div>
      </div>
      <div className={styles.categories}>
        <button
          aria-label="All Categories"
          onClick={() => setShowCategories(_ => !_)}
        >
          All Categories <Icon name="down" />
        </button>

        {showCategories ? (
          <div className={styles.categoriesOpen}>
            {categoriesList.map(({ name }, index) => (
              <div
                className={styles.category}
                style={{ animationDelay: `${0.1 + index * 0.1}s` }}
                key={index}
              >
                <Checkbox name={name} id={name.toLowerCase()} />
                <label htmlFor={name.toLowerCase()}>{name}</label>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <h2 className={styles.category}>Accessories</h2>
      <h2 className={styles.category}>Lighting</h2>
      <h2 className={styles.category}>Routers</h2>
      <h2 className={styles.category}>Sockets & Plugs</h2>
      <h2 className={styles.category}>Security</h2>
      <h2 className={styles.category}>Management</h2>
    </div>
  )
}
