import { useState } from 'react'

import SubCategory from './subCategories'
import { Icon } from 'elements'
import { categoriesList } from 'data'
import { ICategory, ShowContent } from 'types'

import styles from './categories.module.scss'

export default function Categories({ category }: ICategory) {
  const [showContent, setShowContent] = useState<ShowContent>({})

  const toggleContent = (categoryId: string) => {
    setShowContent(prevShowContent => ({
      ...prevShowContent,
      [categoryId]: !prevShowContent[categoryId],
    }))
  }

  return (
    <div className={styles.main}>
      <h3>Categories</h3>

      {categoriesList.map(({ name, items }, i) => (
        <div className={styles.sCategory} key={i}>
          <div
            className={styles.title}
            onClick={() => toggleContent(name)}
            style={
              category?.includes(name)
                ? { color: 'var(--black)' }
                : { color: 'var(--grey400)' }
            }
          >
            <span>{name}</span>
            <Icon name="arrow" className={styles.arrow} />
          </div>
          {showContent[name] && (
            <SubCategory
              items={items}
              checked={category?.includes(name) || false}
            />
          )}
        </div>
      ))}
    </div>
  )
}
