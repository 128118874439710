import { Select, Icon } from 'elements'
import { INoItems } from 'types'

import styles from './noItems.module.scss'

export default function NoItems({ numberOfItems, setNumberOfItems }: INoItems) {
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setNumberOfItems(Number(event.target.value))
  }

  return (
    <div className={styles.items}>
      <button
        onClick={() => numberOfItems > 1 && setNumberOfItems(numberOfItems - 1)}
        disabled={numberOfItems <= 1}
      >
        <Icon name="minus" />
      </button>
      <Select
        name="numberOfItems"
        id="numberOfItems"
        value={numberOfItems}
        onChange={handleOptionChange}
      >
        {[...Array(101).keys()].map(number => (
          <option key={number} value={number}>
            {number}
          </option>
        ))}
      </Select>
      <button
        onClick={() =>
          numberOfItems < 100 && setNumberOfItems(numberOfItems + 1)
        }
        disabled={numberOfItems >= 100}
      >
        <Icon name="add" color="var(--blue)" />
      </button>
    </div>
  )
}
