import { IColors } from 'types'

import styles from './colors.module.scss'

export default function Colors({
  colors,
  selectedColor,
  setSelectedColor,
}: IColors) {
  const handleColorClick = (color: string) => {
    setSelectedColor(color)
  }

  return (
    <div className={styles.main}>
      <p>
        Color: <span>{selectedColor}</span>
      </p>
      <div className={styles.colors}>
        {colors.map((color: string) => (
          <div
            style={{
              backgroundColor: color.replace(/\s/g, ''),
              border:
                color === selectedColor
                  ? '2px solid var(--orange)'
                  : '2px solid transparent',
            }}
            onClick={() => handleColorClick(color)}
            className={styles.color}
          />
        ))}
      </div>
    </div>
  )
}
