import { SetStateAction, Dispatch } from 'react'
import { Link } from 'react-router-dom'

import { useApp } from 'context'
import { Paths } from 'routes'
import { Hamburger } from 'elements'

import styles from './navbar.module.scss'

type TopBarProps = {
  setOpenMobileMenu: Dispatch<SetStateAction<boolean>>
  openMobileMenu: boolean
}

export default function TopBar(props: TopBarProps) {
  const { isAdmin } = useApp()
  const { setOpenMobileMenu, openMobileMenu } = props

  return (
    <div className={styles.topBar}>
      <div className={styles.spaced}>
        <div>
          <Link className={styles.link} to={Paths.Shipping}>
            Shipping
          </Link>
          <Link className={styles.link} to={Paths.Faq}>
            FAQ
          </Link>
          <Link className={styles.link} to={Paths.Contact}>
            Contact
          </Link>
          <a href="tel:+254743665574" className={styles.helpLink}>
            <span className={styles.helpText}> Need Help?</span> &nbsp; +254 743
            665 574
          </a>
        </div>
        <div className={styles.rightTopBar}>
          {isAdmin ? (
            <Link className={styles.link} to={Paths.Admin}>
              Admin
            </Link>
          ) : null}
          <p>KSH</p>
          <p>en</p>
        </div>
        <div
          className={styles.mobileMenuLink}
          onClick={() => setOpenMobileMenu(_ => !_)}
        >
          <p>MENU</p>
          <Hamburger isOpen={openMobileMenu} />
        </div>
      </div>
    </div>
  )
}
