import { ReactNode } from 'react'

import { AuthenticatedROUTES, ROUTES, AdminROUTES } from 'routes'
import { Navbar, Footer } from 'components'
import { useApp } from 'context'

const Wrapper = ({ children }: { children: ReactNode }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <div style={{ flex: 1 }}>
      <Navbar />
      <div className="bodyContent">{children}</div>
    </div>
    <Footer />
  </div>
)

export default function App() {
  const { isAdmin } = useApp()
  const isAuthenticated = false

  if (isAdmin) return <Wrapper children={<AdminROUTES />} />
  if (isAuthenticated) return <Wrapper children={<AuthenticatedROUTES />} />
  return <Wrapper children={<ROUTES />} />
}
