import * as Pages from 'pages'
import { Paths } from 'routes/paths'

export const commonRoutes = [
  {
    path: Paths.Home,
    element: <Pages.Home />,
  },
  {
    path: Paths.Search,
    element: <Pages.Search />,
  },
  {
    path: Paths.Product,
    element: <Pages.Product />,
  },
  {
    path: Paths.Shipping,
    element: <Pages.Shipping />,
  },
  {
    path: Paths.Faq,
    element: <Pages.Faq />,
  },
  {
    path: Paths.Contact,
    element: <Pages.Contact />,
  },
  {
    path: Paths.Terms,
    element: <Pages.Terms />,
  },
  {
    path: Paths.PrivacyPolicy,
    element: <Pages.Privacy />,
  },
  {
    path: Paths.ReturnPolicy,
    element: <Pages.Returns />,
  },
  {
    path: Paths.About,
    element: <Pages.About />,
  },
  {
    path: Paths.Cart,
    element: <Pages.Cart />,
  },
  {
    path: Paths.RequestProduct,
    element: <Pages.Contact request />,
  },
  {
    path: Paths.Page404,
    element: <Pages.Page404 />,
  },
]
