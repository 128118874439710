import { createContext, FC, useContext } from 'react'

import { AppContextInterface } from 'types'
import { Auth } from './auth/auth'
import { Products } from './products/products'
import { ImagesUpload } from './image/image'
import { Cart } from './cart/cart'
import { AddItems } from './addItems/addItems'
import { Categories } from './categories/categories'

const Context = createContext<AppContextInterface>({} as AppContextInterface)

export const useApp = () => useContext(Context)

interface Props {
  children: JSX.Element[] | JSX.Element
}

const StateProvider: FC<Props> = ({ children }) => {
  const authValues = Auth()
  const productsValues = Products()
  const imageValues = ImagesUpload()
  const cartValues = Cart()
  const addItems = AddItems()
  const categories = Categories()

  return (
    <Context.Provider
      value={{
        ...authValues,
        ...productsValues,
        ...imageValues,
        ...cartValues,
        ...addItems,
        ...categories,
      }}
      children={children}
    />
  )
}

export default StateProvider
