import { Icon, Image } from 'elements'

import styles from './styles.module.scss'

export default function BigPromoCard() {
  return (
    <div className={styles.leftPromoCard}>
      <div className={styles.textCard}>
        <Icon name="leftPromo" className={styles.leftPromo} />
        <p>Bestsellers</p>
        <h2>Find your headphones</h2>
        <button aria-label="Shop Now">shop now</button>
      </div>
      <Image
        width={248}
        height={350}
        src="https://picsum.photos/514/460"
        alt="headphone"
        className={styles.promoImg}
      />
    </div>
  )
}
