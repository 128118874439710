import { useState, useEffect } from 'react'

import { fba } from 'context/firebase'
import { CategoriesInterface } from 'types'

export const Categories = () => {
  const { getDocs, doc, db, collection } = fba

  const [parentCategories, setParentCategories] = useState<
    CategoriesInterface[]
  >([])
  const [childCategories, setChildCategories] = useState<CategoriesInterface[]>(
    []
  )

  useEffect(() => {
    ;(async () => {
      const querySnap = await getDocs(collection(db, 'categories'))
      const categories = querySnap.docs.map(doc =>
        doc.data()
      ) as unknown as CategoriesInterface[]

      if (categories) {
        const parentCategories = categories.filter(
          ({ Type }) => Type === 'Parent'
        )
        setParentCategories(parentCategories)

        const childCategories = categories.filter(
          ({ Type }) => Type === 'Child'
        )
        setChildCategories(childCategories)
      }
    })()
  }, [doc, db, getDocs, collection])

  return { parentCategories, childCategories }
}
