import { useState } from 'react'

import { Image, useWindowWidth } from 'elements'
import { imgInterface } from 'types'

import styles from './images.module.scss'

export default function Images({ imgs, discount, title }: imgInterface) {
  const [selectedImg, setSelectedImg] = useState(imgs[0])
  const otherImgs = imgs.filter(img => img !== selectedImg)
  const handleImgClick = (img: string) => setSelectedImg(img)
  const { width } = useWindowWidth()

  return (
    <div className={styles.main}>
      <div className={styles.mImg}>
        <Image
          src={selectedImg}
          alt={title}
          width={width < 1007 ? width : 514}
          height={width < 1007 ? width : 514}
        />
        {discount > 0 && <span className={styles.discount}>{discount}%</span>}
      </div>
      <div className={styles.images}>
        {otherImgs.map(img => (
          <Image
            src={img}
            alt={title}
            width={118}
            height={118}
            onClick={() => handleImgClick(img)}
          />
        ))}
      </div>
    </div>
  )
}
