import { useEffect } from 'react'

import { Icon } from 'elements'

import styles from './logos.module.scss'

export default function PromoLogos() {
  useEffect(() => {
    const isElementInViewport = (j: HTMLDivElement) =>
      j?.getBoundingClientRect().right > 0
    const flavoursContainer = document.getElementById('promoLogosWrapper')
    const flavoursScrollWidth = flavoursContainer?.scrollWidth

    const carousel = setInterval(() => {
      const first = document.querySelector(
        '#promoLogosWrapper svg'
      ) as HTMLDivElement

      if (!isElementInViewport(first)) {
        flavoursContainer?.appendChild(first)
        flavoursContainer?.scrollTo(
          flavoursContainer.scrollLeft - first.offsetWidth,
          0
        )
      }

      if (flavoursContainer?.scrollLeft !== flavoursScrollWidth) {
        flavoursContainer?.scrollTo(flavoursContainer.scrollLeft + 1, 0)
      }
    }, 30)

    return () => clearInterval(carousel)
  }, [])

  return (
    <div className={styles.wrapper} id="promoLogosWrapper">
      <Icon name="appleLogo" className={styles.logo} />
      <Icon name="sonyLogo" className={styles.logo} />
      <Icon name="asusLogo" className={styles.logo} />
      <Icon name="canonLogo" className={styles.logo} />
      <Icon name="dellLogo" className={styles.logo} />
      <Icon name="philipsLogo" className={styles.logo} />
      <Icon name="lenovoLogo" className={styles.logo} />
    </div>
  )
}
