import cn from 'classnames'

import styles from './animatedHamburger.module.scss'

export default function AnimatedHamburger({ isOpen }: { isOpen: boolean }) {
  return (
    <div className={cn({ [styles.open]: isOpen }, styles.icon)}>
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  )
}
