import {
  PopularProducts,
  PromoLogos,
  ProductPromoCards,
  BigPromoCard,
  NewProducts,
  PromoProducts,
} from 'components'

import styles from './home.module.scss'

export default function Home() {
  return (
    <div className={styles.home}>
      <div className={styles.promos}>
        <BigPromoCard />
        <ProductPromoCards />
      </div>
      <PromoLogos />
      <PopularProducts />
      <PromoProducts />
      <NewProducts />
    </div>
  )
}
