import { ITags } from 'types'
import { Icon } from 'elements'

import styles from './tags.module.scss'

export default function Tags({ searchTerms, setSearchTerms, terms }: ITags) {
  const price = searchTerms?.price
  const brand = searchTerms?.brand
  const category = searchTerms?.category
  const term = searchTerms?.term

  const deleteSearchTag = (deleteTerm: string) => {
    if (price?.includes(deleteTerm as any)) {
      const newPrice = price.filter(item => item !== (deleteTerm as any))
      setSearchTerms({ ...searchTerms, price: newPrice })
    } else if (brand?.includes(deleteTerm)) {
      const newBrand = brand.filter(item => item !== deleteTerm)
      setSearchTerms({ ...searchTerms, brand: newBrand })
    } else if (category?.includes(deleteTerm)) {
      const newCategory = category.filter(item => item !== deleteTerm)
      setSearchTerms({ ...searchTerms, category: newCategory })
    } else if (term === deleteTerm) {
      setSearchTerms({ ...searchTerms, term: '' })
    }
  }

  const deleteAllSearchTerms = () => {
    setSearchTerms({ price: [], term: '', brand: [], category: [] })
  }

  return (
    <div className={styles.lDelete}>
      <div className={styles.dCategories}>
        {terms?.map((term, i) => (
          <div className={styles.term} key={i}>
            <span>
              {term === 'a'
                ? '$0 - $100'
                : term === 'b'
                ? '$100 - $200'
                : term === 'c'
                ? '$200 - $300'
                : term === 'd'
                ? '$300 - $400'
                : term === 'e'
                ? '$400 - $500'
                : term === 'f'
                ? '$500 - $Above'
                : term}
            </span>
            <button onClick={() => deleteSearchTag(term)}>
              <Icon name="close" />
            </button>
          </div>
        ))}
      </div>
      <h3 onClick={deleteAllSearchTerms}>Delete all</h3>
    </div>
  )
}
