import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  signOut,
  signInAnonymously as signInAnon,
} from 'firebase/auth'
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  getDocs,
  collection,
  addDoc,
} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDczpoBlUHKzLEV9P0RWxBziI01XCRi378',
  authDomain: 'smartlife-ke.firebaseapp.com',
  projectId: 'smartlife-ke',
  storageBucket: 'smartlife-ke.appspot.com',
  messagingSenderId: '214292870295',
  appId: '1:214292870295:web:216add2a643de85d2be5d2',
  measurementId: 'G-5N65111GHS',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const analytics = getAnalytics(app)
const provider = new GoogleAuthProvider()
const db = getFirestore()

export const fba = {
  getDoc,
  setDoc,
  analytics,
  setPersistence,
  signInWithPopup,
  browserSessionPersistence,
  onAuthStateChanged,
  signOut,
  db,
  getDocs,
  collection,
  doc,
  auth,
  provider,
  signInAnon,
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  addDoc,
}
