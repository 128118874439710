import loadable from '@loadable/component'

import Home from './home/home'

const Page404 = loadable(() => import('./404/404'))
const Account = loadable(() => import('./account/account'))
const AddProduct = loadable(() => import('./admin/addProduct/addProduct'))
const Address = loadable(() => import('./address/address'))
const Cart = loadable(() => import('./cart/cart'))
const Login = loadable(() => import('./login/login'))
const Orders = loadable(() => import('./orders/orders'))
const Product = loadable(() => import('./product/product'))
const Search = loadable(() => import('./search/search'))
const Contact = loadable(() => import('./static/contact'))
const Privacy = loadable(() => import('./static/privacy'))
const Shipping = loadable(() => import('./static/shipping'))
const Terms = loadable(() => import('./static/terms'))
const Faq = loadable(() => import('./static/faq'))
const Returns = loadable(() => import('./static/returns'))
const About = loadable(() => import('./static/about'))
const Products = loadable(() => import('./products/products'))
const AdminCategory = loadable(() => import('./admin/category/category'))
const Admin = loadable(() => import('./admin/admin'))
const ColorsAndBrands = loadable(
  () => import('./admin/colorsAndBrands/colorsAndBrands')
)
const IndividualOrder = loadable(
  () => import('./individualOrder/individualOrder')
)

export {
  Page404,
  Account,
  AddProduct,
  Address,
  Cart,
  Home,
  IndividualOrder,
  Login,
  Orders,
  Product,
  Search,
  Contact,
  Privacy,
  Shipping,
  Terms,
  Faq,
  Returns,
  About,
  Products,
  Admin,
  ColorsAndBrands,
  AdminCategory,
}
