import { useState, useEffect } from 'react'

import { fba } from 'context/firebase'
import { ImageUploadInterface, ImageReturnTypeInterface } from 'types'

export const ImagesUpload = (): ImageReturnTypeInterface => {
  const { getStorage, ref, getDownloadURL, uploadBytesResumable } = fba
  const storage = getStorage()

  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadError, setUploadError] = useState(false)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [progress, setProgress] = useState(0)
  const [urls, setUrls] = useState<string[]>([])

  useEffect(() => {
    setProgress(Math.round((uploadProgress / numberOfFiles) * 10) / 10)
    if (uploadError || progress >= 100 || urls.length === numberOfFiles)
      setUploading(false)
  }, [uploadProgress, numberOfFiles, uploadError, progress, urls])

  const uploadImage = async ({ files, location }: ImageUploadInterface) => {
    setUrls([])
    const storageRef = ref(storage, location)
    setNumberOfFiles(files.length)
    setUploading(true)

    for (let i = 0; i < files.length; i++) {
      let prevValue = 0
      const file = files[i]
      const fileRef = ref(storageRef, file.name)
      const uploadTask = uploadBytesResumable(fileRef, file)

      uploadTask.on(
        'state_changed',
        s => {
          const value = (s.bytesTransferred / s.totalBytes) * 100
          setUploadProgress(x => x - prevValue + value)
          prevValue = value
        },
        error => error && setUploadError(true),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(url =>
            setUrls(x => [...x, url])
          )
        }
      )
    }
  }

  return {
    uploading,
    uploadImage,
    uploadProgress: progress,
    urls,
    uploadError,
  }
}
