import { Link, useNavigate } from 'react-router-dom'

import { categoriesList } from 'data'
import { Icon, scrollTop } from 'elements'
import { Paths } from 'routes'

import styles from './footer.module.scss'

export default function Footer() {
  const navigate = useNavigate()
  const randomCategories = categoriesList
    .sort(() => Math.random() - 0.5)
    .slice(0, 5)

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.copyright}>
          <Icon name="logo" />
          <p>
            &copy; {new Date().getFullYear()} Castynet Studios.
            <br />
            All rights reserved.
          </p>
        </div>
        <div className={styles.categories}>
          <h3>Categories</h3>
          {randomCategories.map(({ name }, index) => (
            <span key={name + index}>
              <p
                onClick={() =>
                  navigate(Paths.Search, { state: { category: name } })
                }
                className={styles.categoryLink}
              >
                {name}
              </p>
              {index % 2 !== 0 ? <br /> : null}
            </span>
          ))}
        </div>
        <div className={styles.pages}>
          <h3>Legal</h3>
          <Link to={Paths.Terms}>Terms of service</Link>
          <Link to={Paths.PrivacyPolicy}>Privacy policy</Link>
          <Link to={Paths.Shipping}>Shipping Policy</Link>
          <Link to={Paths.ReturnPolicy}>Return Policy</Link>
        </div>
        <div className={styles.pages}>
          <h3>About</h3>
          <Link to={Paths.About}>About us</Link>
          <Link to={Paths.Faq}>FAQ</Link>
        </div>
        <div className={styles.pages}>
          <h3>Contact</h3>
          <Link to={Paths.Contact} onClick={() => scrollTop()}>
            Contact us
          </Link>
          <Link to={Paths.RequestProduct} onClick={() => scrollTop()}>
            Request a product
          </Link>
          <p>
            <Icon name="mail" />
            <a href="mailto:sales@cozybyte.tech">sales@cozybyte.tech</a>
          </p>
          <p>
            <Icon name="phone" />
            <a href="tel:+254743665574">+254 743 665 574</a>
          </p>
        </div>
      </div>
      <Icon name="footerShape" className={styles.footerShape} />
    </footer>
  )
}
