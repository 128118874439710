export const Paths = {
  Page404: '*',
  Account: '/account',
  AddProduct: '/add-product',
  Address: '/address',
  Cart: '/cart',
  Home: '/',
  IndividualOrder: '/individual-order',
  Login: '/login',
  Orders: '/orders',
  Product: '/product/:id',
  Search: '/search',
  Shipping: '/shipping',
  Faq: '/faq',
  Contact: '/contact',
  Terms: '/terms',
  About: '/about',
  ReturnPolicy: '/return-policy',
  PrivacyPolicy: '/privacy-policy',
  Products: '/products',
  AdminProduct: '/admin-product',
  RequestProduct: '/request-product',
  Admin: '/admin',
  Categories: '/categories',
  ColorsAndBrands: '/colors-and-brands',
}
