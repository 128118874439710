import { Checkbox } from 'elements'
import { SubCategoryInterface } from 'types'

import styles from './subCategories.module.scss'

export default function SubCategory({ items, checked }: SubCategoryInterface) {
  return (
    <div className={styles.container}>
      {items.map(item => (
        <div className={styles.item}>
          <Checkbox name={item} checked={checked} />
          <span>{item}</span>
        </div>
      ))}
    </div>
  )
}
