import { fba } from 'context/firebase'
import {
  ProductCreationType,
  AddItemsInterface,
  CategoriesInterface,
} from 'types'

export const AddItems = (): AddItemsInterface => {
  const { collection, db, addDoc } = fba

  const addProduct = (product: ProductCreationType) => {
    console.log(product)
    return false
  }

  const addCategory = async (category: CategoriesInterface) => {
    const collectionRef = collection(db, 'categories')
    const doc = await addDoc(collectionRef, category)
    if (doc.id) return true
    return false
  }

  const addColor = () => {
    return false
  }

  const addBrand = () => {
    return false
  }

  return { addProduct, addCategory, addColor, addBrand }
}
