import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DocumentData, DocumentReference } from 'firebase/firestore'
import { User } from 'firebase/auth'

import { useCurrentPath } from 'elements'
import { AuthReturnTypeInterface, ContentInterface } from 'types'
import { fba } from 'context/firebase'
import { Paths } from 'routes'

export const Auth = (): AuthReturnTypeInterface => {
  const { getDoc, doc, db, onAuthStateChanged, auth, setDoc } = fba

  const [user, setUser] = useState<User>()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [authenticating, setAuthenticating] = useState(false)
  const [userRef, setUserRef] = useState<DocumentReference<DocumentData>>()
  const [isAdmin, setIsAdmin] = useState(false)

  const currentPath = useCurrentPath()
  const navigate = useNavigate()
  const isAuthPath = [Paths.Login].includes(currentPath)

  useEffect(() => {
    async function fetchAndAddUser(paramUser?: User) {
      const userRaw = await getDoc(doc(db, 'users', paramUser?.uid || ''))
      if (!userRaw.exists()) {
        const content: ContentInterface = {
          displayName: paramUser?.displayName,
          phoneNumber: paramUser?.phoneNumber,
          avatar: paramUser?.photoURL,
          email: paramUser?.email,
        }
        setDoc(doc(db, 'users', paramUser?.uid || ''), content, { merge: true })
        fetchAndAddUser(paramUser)
        return
      }

      const user = userRaw.data()
      setUser(user as User)
      setAuthenticating(false)
      setIsLoggedIn(true)
    }

    onAuthStateChanged(auth, user => {
      if (user) {
        setUserRef(doc(db, 'users', user.uid))
        fetchAndAddUser(user)
        if (isAuthPath) navigate(Paths.Home)
      }
    })
  }, [auth, db, doc, getDoc, isAuthPath, onAuthStateChanged, navigate, setDoc])

  useEffect(() => {
    if (userRef?.id) {
      ;(async () => {
        const docSnap = await getDoc(doc(db, 'admins', userRef?.id || ''))
        if (docSnap.exists()) {
          setIsAdmin(true)
          return
        }

        setIsAdmin(false)
      })()
    }
  }, [userRef, doc, db, getDoc])

  async function fetchUser(uid: string) {
    const docSnap = await getDoc(doc(db, 'users', uid || ''))
    if (!docSnap.exists()) return null
    return docSnap.data()
  }

  // get the user data, if user is none existent, create one
  async function fetchAndAddUser(paramUser?: User) {
    const user = await fetchUser(paramUser?.uid || '')

    if (!user) {
      const content: ContentInterface = {
        displayName: paramUser?.displayName,
        phoneNumber: paramUser?.phoneNumber,
        avatar: paramUser?.photoURL,
        email: paramUser?.email,
      }
      setDoc(doc(db, 'users', paramUser?.uid || ''), content, { merge: true })
      fetchAndAddUser(paramUser)
      return
    }

    setUser(user as User)
    setAuthenticating(false)
    setIsLoggedIn(true)
  }

  // register using google the user in then send them to complete sign up
  async function registerWithGoogle() {
    setAuthenticating(true)
    fba.setPersistence(auth, fba.browserSessionPersistence)
    const result = await fba.signInWithPopup(auth, fba.provider)
    setUserRef(doc(db, 'users', result.user.uid))
    fetchAndAddUser(result.user)
  }

  // signIn using google. return error if user is not found
  async function signInWithGoogle() {
    setAuthenticating(true)
    fba.setPersistence(auth, fba.browserSessionPersistence)
    const result = await fba.signInWithPopup(auth, fba.provider)
    const user = await fetchUser(result.user.uid)

    if (!user) {
      throw new Error('User not found')
    }

    setUserRef(doc(db, 'users', result.user.uid))
    fetchAndAddUser(result.user)
  }

  // logout and reload to lose state
  async function logOut() {
    fba.signOut(auth)
    window.location.reload()
  }

  return {
    isLoggedIn,
    authenticating,
    logOut,
    registerWithGoogle,
    user,
    signInWithGoogle,
    isAdmin,
  }
}
