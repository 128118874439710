import { forwardRef, useState, useEffect } from 'react'

import styles from './checkbox.module.scss'

export interface ICheckboxProps {
  checked?: boolean
  disabled?: boolean
  id?: string
  name?: string
  onChange?: (checked: boolean) => void
  value?: string
  className?: string
  style?: React.CSSProperties
}

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({ checked = false, onChange, ...props }, ref) => {
    const [isChecked, setIsChecked] = useState(checked)

    useEffect(() => {
      setIsChecked(checked)
    }, [checked])

    const handleChange = () => {
      onChange && onChange(!isChecked)
      setIsChecked(!isChecked)
    }

    return (
      <span className={styles.checkBox}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          {...props}
          ref={ref}
        />
        <span onClick={handleChange} aria-label={props.name + 'checkbox'} />
      </span>
    )
  }
)
