import { Paths } from 'routes/paths'
import * as Pages from 'pages'

export const authenticatedRoutes = [
  {
    path: Paths.Address,
    element: <Pages.Address />,
  },
  {
    path: Paths.IndividualOrder,
    element: <Pages.IndividualOrder />,
  },
  {
    path: Paths.Orders,
    element: <Pages.Orders />,
  },
  {
    path: Paths.Account,
    element: <Pages.Account />,
  },
  {
    path: Paths.Account,
    element: <Pages.Account />,
  },
]
