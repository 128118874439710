import { ImgHTMLAttributes, useState, useEffect } from 'react'
import cn from 'classnames'

import styles from './image.module.scss'

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  width: number
  height: number
}

export default function Image(props: ImageProps) {
  const { alt, src, width, height, className, ...rest } = props
  const w = width - 10
  const h = height - 10
  const [loaded, setLoaded] = useState(false)
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const [canAnimate, setCanAnimate] = useState(false)

  const widthDots = Math.ceil(w / 5 / 15)
  const heightDots = Math.ceil(h / 5 / 15)

  useEffect(() => {
    if (!loaded) return

    const timeout = setTimeout(() => {
      setShowPlaceholder(false)
    }, 400)

    return () => clearTimeout(timeout)
  }, [loaded])

  useEffect(() => {
    window.addEventListener('load', () => setCanAnimate(true))
    return () => window.removeEventListener('load', () => {})
  }, [])

  return (
    <div className={styles.wrapper}>
      <img
        alt={alt}
        src={src}
        width={width}
        height={height}
        className={cn({ [styles.loaded]: loaded }, className)}
        onLoad={() => setLoaded(true)}
        {...rest}
      />
      {showPlaceholder ? (
        <div
          className={cn({ [styles.loaded]: loaded }, styles.placeholder)}
          style={{ width: w, height: h }}
        >
          {[...Array(widthDots)].map((_, i) => (
            <div className={styles.dotRow} key={i}>
              {[...Array(heightDots)].map((_, j) => (
                <div
                  className={cn(styles.dot, { [styles.animate]: canAnimate })}
                  key={j}
                  style={{ animationDelay: `${Math.random() * 0.5}s` }}
                />
              ))}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
